


const Types = {
    SET_ME: 'SET_ME',
    ME_ERROR: 'ME_ERROR',
    SET_USERS: 'SET_USERS',
    SET_NONUSER: 'SET_NONUSER',
    NONUSER_ERROR: 'NONUSER_ERROR',
    SET_ATTACHMENTS: 'SET_ATTACHMENTS',
    ATTACHMENTS_ERROR: 'ATTACHMENTS_ERROR',
    UPDATE_SIM_AC_STATUE: 'UPDATE_SIM_AC_STATUE',
    DELETE_ATTACHMENTS: 'DELETE_ATTACHMENTS',
    SET_SIMS: 'SET_SIMS',
    SIMS_ERROR: 'SIMS_ERROR',
    SET_NONACORDER: 'SET_NONACORDER',
    UPDATE_ORDER_AC_STATUS: 'UPDATE_ORDER_AC_STATUS',
    ORDERS_ERROR: 'ORDERS_ERROR',
    SET_ORDER: 'SET_ORDER',
    GET_OFFERS: 'GET_OFFERS',
    OFFERS_ERROR:'OFFERS_ERROR'
    
}


export default Types